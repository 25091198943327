<template>
  <div
    v-if="show"
    class="ProductPrice relative type-sm flex flex-col justify-start gap-0 desk:gap-2"
  >
    <div v-if="userPrice.status === 'ok' && design !== 'list'">
      <p
        v-for="(flag, index) in displayFlags"
        :key="'flag-' + flag.text"
        class="inline type-headline-xs desk:type-headline-sm"
      >
        {{ flag.text }}
        <span v-if="index + 1 !== displayFlags.length">
          -
        </span>
      </p>
    </div>
    <div
      v-if="userPrice.status === 'logginIn' || userPrice.status === 'pending'"
      class="priceLoadBox"
    />
    <h2
      v-if="userPrice.status === 'ok'"
      class="whitespace-nowrap text-darkest"
      :class="{
        'text-criticalDark': isUserDiscounted,
        'type-headline-xl': design !== 'list',
        'type-sm-medium': design === 'list'

      }"
    >
      {{ userPrice.price?.priceBeforeVatDisplay }}
    </h2>
    <p
      v-if="(userPrice.price?.isCustomerPrice && userPrice.price?.discountPercentage > 0) || userPrice.price?.isCampaignPrice"
      class="text-dark"
      :class="{'leading-single': design === 'list'}"
    >
      {{ $t('productPage.price.original') }} {{ userPrice.price?.priceBeforeDiscountBeforeVatDisplay }}
      <span v-if="userPrice.price?.isCustomerPrice" :class="{'hidden': design === 'list'}">
        ({{ $t('productPage.price.yourDiscount') }} <span class="text-criticalDark">{{ userPrice.price?.discountPercentage }}%</span>)
      </span>
    </p>
    <p
      v-if="userPrice.price?.recommendedPriceDisplay"
      class="type-xs desk:type-sm text-dark"
      :class="{
        'leading-single': design === 'list',
      }"
    >
      {{ $t('product.recommendedPrice.short') }} {{ userPrice.price?.recommendedPriceDisplay }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import { ProductVariantModel } from '~/models/productVariant';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import useMultiDiscount from '~/composeables/useMultiDiscount';

const uiStore = useUiStore();

const props = defineProps<{
  productItem: ProductModel,
  activeVariant: ProductVariantModel,
  crossell?: boolean,
  design: 'standard' | 'list'
}>();

const { hasCampaign, giftCampaigns } = useMultiDiscount(props);

const userStore = useUserStore();

const userPrice = computed(()=> {
  return userStore.getPrice(props.activeVariant.partNo, props.crossell ?? false);
});

const isUserDiscounted = computed(()=> {
  return userStore.isSalePrice(props.activeVariant.partNo);
});

const displayFlags = computed(()=> {
  const isInStock = props.activeVariant.stockStatuses?.some((s) => s.inStock > 0);
  if (isInStock) {
    return props.productItem.displayFlags.filter((f)=> f.code !== 'onDemand');
  }
  return props.productItem.displayFlags;
});

const show = computed(() => {
  return true;
  /*if (giftCampaigns.value.length) {
    return true;
  }
  return !hasCampaign || (hasCampaign && userPrice.value.price?.priceBeforeDiscountBeforeVat === userPrice.value.price?.priceBeforeVat);*/
});

</script>

<style scoped lang="postcss">

.priceLoadBox {
  @apply block mt-4 w-64 min-h-24;
  color: rgba(0,0,0,0);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes spin {
  from {
    background-color: #DEDDD9;
  }
  to {
    background-color: #F5F4F0;
  }
}

</style>
